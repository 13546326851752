import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
const DriveHopper = Loadable(lazy(() => import('../views/dashboard/drive/Hopper')));
const DriveKrypto = Loadable(lazy(() => import('../views/dashboard/drive/Krypto')));
const DriveHash = Loadable(lazy(() => import('../views/dashboard/drive/Hash')));
const SpyDogs = Loadable(lazy(() => import('../views/dashboard/drive/SpyDogs')));
const Documenatation = Loadable(lazy(() => import('../views/dashboard/Documentation')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));
// profile page routing
//const ProfileSettings = Loadable(lazy(() => import('../views/profile settings')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard/default',
                '/dashboard/drive/Hopper',
                '/dashboard/drive/Hash',
                '/dashboard/drive/Krypto',
                '/dashboard/drive/SpyDogs',
                '/dashboard/documentation',
                '/utils/util-typography',
                '/utils/util-color',
                '/utils/util-shadow',
                '/icons/tabler-icons',
                '/icons/material-icons',
                '/sample-page'
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                <AuthGuard>
                        <Route path="/dashboard/default" component={DashboardDefault} />
                        <Route path="/dashboard/drive/Hopper" component={DriveHopper} />
                        <Route path="/dashboard/drive/Krypto" component={DriveKrypto} />
                        <Route path="/dashboard/drive/Hash" component={DriveHash} />
                        <Route path="/dashboard/drive/SpyDogs" component={SpyDogs} />
                        <Route path="/dashboard/documentation" component={Documenatation} />
                        <Route path="/utils/util-typography" component={UtilsTypography} />
                        <Route path="/utils/util-color" component={UtilsColor} />
                        <Route path="/utils/util-shadow" component={UtilsShadow} />
                        <Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
                        <Route path="/icons/material-icons" component={UtilsMaterialIcons} />
                        <Route path="/sample-page" component = {SamplePage} />
                </AuthGuard>    
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
